import React, { lazy, Suspense, useEffect, useState } from 'react';
import Preloader from './components/loader/Preloader';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import menuItems from './route';
const ProjectPage = lazy(() => import('./pages/ProjectPage'));
const ProjectDetails = lazy(() => import('./pages/ProjectDetails'));
const TopBar = lazy(() => import('./components/header/TopBar'));
const HomePage = lazy(() => import('./pages/HomePage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const HeaderComponents = lazy(() => import('./components/header/HeaderComponents'));
const BecomeVolunteerPage = lazy(() => import('./pages/BecomeVolunteerPage'));
const StickyHeader = lazy(() => import('./components/header/StickyHeader'));
const DonationDetailsPage = lazy(() => import('./pages/DonationDetailsPage'));
const TCPage = lazy(() => import('./pages/TCPage'));
const PolicyPage = lazy(() => import('./pages/PolicyPage'));
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy'));
const FooterComponents = lazy(() => import('./components/footer/FooterComponents'));

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleScroll = () => {
    setTimeout(() => {
      setScrollY(window.scrollY);
    }, 2000);
  };

  useEffect(() => {
    // Add the event listener for scroll when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on route change
  }, [location.pathname]);


  const handleMenuToggle = () => {
    setIsMenuOpen((v) => !v);
  };

  useEffect(() => {
    const body = document.body;
    const mobileNavWrapper = document.querySelector('.mobile-nav__wrapper');

    if (isMenuOpen) {
      // Append "locked" class to the body
      body.classList.add('locked');

      // Append "expanded" class to the mobile navigation wrapper
      if (mobileNavWrapper) {
        mobileNavWrapper.classList.add('expanded');
      }
    } else {
      // Remove "locked" class from the body
      body.classList.remove('locked');

      // Remove "expanded" class from the mobile navigation wrapper
      if (mobileNavWrapper) {
        mobileNavWrapper.classList.remove('expanded');
      }
    }

    // Cleanup to remove classes when component unmounts
    return () => {
      body.classList.remove('locked');
      if (mobileNavWrapper) {
        mobileNavWrapper.classList.remove('expanded');
      }
    };
  }, [isMenuOpen]);


  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check for mobile devices
    if (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Suspense fallback={<Preloader />}>
        {/* <div id="google_translate_element"></div> */}
        <div className="page-wrapper">
          <ToastContainer />
          {
            scrollY > 1 ? <StickyHeader menuItems={menuItems} handleMenuToggle={handleMenuToggle} isMobile={isMobile} /> : <>  <TopBar isMobile={isMobile} />
              <HeaderComponents menuItems={menuItems} handleMenuToggle={handleMenuToggle} /></>
          }
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/become-volunteer" element={<BecomeVolunteerPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/donation" element={<DonationDetailsPage />} />
            <Route path="/terms" element={<TCPage />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/privacy-policy" element={<PolicyPage />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
          </Routes>
        </div>
        <FooterComponents handleMenuToggle={handleMenuToggle} isMenuOpen={isMenuOpen} menuItems={menuItems} isMobile={isMobile} />
      </Suspense>
    </>
  );
}

export default App;



